/* eslint-disable max-len */

export const colors = {
  brand: "#0249A1",
  lilac: "#9d7cbf",
  accent: "#ffb238",
  success: "#37b635",
  warning: "#ec1818",
  ui: {
    bright: "#e0d6eb",
    light: "#f5f3f7",
    whisper: "#fbfafc",
  },
  code: "#fcf6f0",
  gray: {
    dark: "hsla(270, 17.119554496%, 0%, 0.92)",
    copy: "hsla(270, 15.797828016000002%, 0%, 0.88)",
    calm: "rgba(0, 0, 0, 0.54)",
  },
  white: "#fff",
  black: "#000",
  blau: "#0249A1",
  hellblau: "#0099CC",
  orange: "#FF6600",
  gelb: "#FFCC00",
};

export const fonts = {
  helvetica: {
    regular: '"Helvetica Regular", "sans-serif"',
    bold: '"Helvetica Bold", "sans-serif"',
    italic: '"Helvetica Italic", "sans-serif"',
    black: { condensed: '"Helvetica Black Condensed", "sans-serif"' },
  },
};

export const breakpoints = {
  xs: 360,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const widths = {
  md: 720,
  lg: 960,
  xl: 1660,
};

export const dimensions = {
  fontSize: {
    regular: 16,
    large: 18,
  },
  headingSizes: {
    h1: 2.441,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25,
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2,
  },
  containerPadding: 1.5,
};
