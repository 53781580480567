import * as React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const StyledLayoutMain = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 70px;

  @media screen and (min-width: ${breakpoints.lg}px) {
    margin-top: 0;
  }
`;

interface LayoutMainProps {
  className?: string;
}

const LayoutMain: React.FC<LayoutMainProps> = ({ children, className }) => (
  <StyledLayoutMain className={className}>{children}</StyledLayoutMain>
);

export default LayoutMain;
