import * as React from "react";
import NavigationListItem from "./NavigationListItem";

import styled from "styled-components";
import { breakpoints, colors, fonts } from "../../styles/variables";

import "normalize.css";

const StyledNavigationList = styled.ul`
  &.level-0 {
    font-family: ${fonts.helvetica.black.condensed};
    letter-spacing: 0.1px;
    color: ${colors.brand};
    list-style-type: none;
    font-size: 28px;
    margin: 0;
    padding: 0;
    padding-top: 40px;
    border-bottom: solid 10px ${colors.brand};
    display: block;
    max-width: 1920px;
    margin: 0 auto;

    &.is-open {
      display: block;
    }

    @media screen and (min-width: ${breakpoints.lg}px) {
      display: flex;
      justify-content: space-around;
      font-size: 25px;
      border-bottom: none;

      &.is-open {
        display: flex;
      }
    }
    @media screen and (min-width: ${breakpoints.xl}px) {
      font-size: 29px;
    }
  }
  &.level-1 {
    font-family: ${fonts.helvetica.regular};
    background-color: white;
    color: ${colors.brand};
    list-style-type: none;
    font-size: 21px;
    line-height: 40px;
    margin: 0;
    padding: 0;
    min-width: 100%;
    transition: all 0.2s;

    @media screen and (min-width: ${breakpoints.lg}px) {
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 1;
      font-family: ${fonts.helvetica.regular};
      background-color: white;
      color: white;
      list-style-type: none;
      font-size: 20px;
      line-height: 50px;
      min-width: 100%;
      transition: all 0.2s;
    }
  }
`;

export type Item = {
  uri: string;
  title: string;
  children?:
    | [
        {
          uri: string;
          title: string;
        }
      ]
    | undefined;
  weiterleitung?: boolean | false;
};

interface NavigationListProps {
  listdata: [];
  levelIndicator: number;
}

const NavigationList: React.FC<NavigationListProps> = ({
  listdata,
  levelIndicator,
}) => {
  return (
    <StyledNavigationList className={`level-${levelIndicator}`}>
      {listdata.map((item: Item) => (
        <NavigationListItem
          uri={item.uri}
          title={item.title}
          children={item.children}
          key={item.uri}
          levelIndicator={levelIndicator}
          redirectto={
            item.children !== undefined &&
            item.children &&
            item.weiterleitung !== undefined &&
            item.weiterleitung
              ? item.children[0].uri
              : ""
          }
        />
      ))}
    </StyledNavigationList>
  );
};
export default NavigationList;
