import * as React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const StyledContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: ${breakpoints.xl}px;
  padding-right: 12px;
  padding-left: 12px;

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding-right: 24px;
    padding-left: 24px;
  }
`;

interface ContainerProps {
  className?: string;
}

const Wrapper: React.FC<ContainerProps> = ({ children, className }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

export default Wrapper;
