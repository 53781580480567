import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { breakpoints, colors } from "../styles/variables";
import Wrapper from "./Wrapper";
import Logo from "../assets/ramsauer-carreisen.svg";
import Saentispanorama from "../assets/saentispanorama.svg";
import Hamburger from "../assets/mobile-navi-opener.svg";
import HamburgerCloser from "../assets/mobile-navi-closer.svg";
import { SetStateAction, Dispatch } from "react";

const StyledHeader = styled.header`
  height: 70px;
  padding-top: 12px;
  color: ${colors.brand};
  background-color: ${colors.brand};
  background-repeat: no-repeat;
  z-index: 20;
  position: fixed;
  width: 100%;

  @media screen and (min-width: ${breakpoints.lg}px) {
    position: static;
    height: calc(96px + 11vw);
    background-image: url(${Saentispanorama});
    background-position: center 65px;
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 30px;
    border-bottom: solid 1px white;
  }

  @media print {
    position: static;
  }
`;

const HomepageLink = styled(Link)`
  color: ${colors.white};
  font-size: 1.5rem;
  font-weight: 600;

  img {
    width: 200px;

    @media screen and (min-width: ${breakpoints.lg}px) {
      width: 280px;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const HamburgerLink = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
    padding: 4px;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    display: none;
  }

  @media print {
    display: none;
  }
`;
interface NavigationProps {
  mobilenav: boolean;
  toggleMobileNav: Dispatch<SetStateAction<boolean>>;
}
const Header: React.FC<NavigationProps> = ({ mobilenav, toggleMobileNav }) => {
  return (
    <StyledHeader>
      <Wrapper>
        <HomepageLink to="/">
          <img src={Logo} alt="Ramsauer Carreisen" />
        </HomepageLink>
        <HamburgerLink
          onClick={() => {
            toggleMobileNav(!mobilenav);
          }}
        >
          {!mobilenav ? (
            <img src={Hamburger} alt="Navigation" />
          ) : (
            <img src={HamburgerCloser} alt="Navigation" />
          )}
        </HamburgerLink>
      </Wrapper>
    </StyledHeader>
  );
};

export default Header;
