import * as React from "react";
import styled from "styled-components";
import NavigationList from "./NavigationList";

import { breakpoints } from "../../styles/variables";
import { SetStateAction, Dispatch } from "react";
import "normalize.css";

const StyledNavigation = styled.nav`
  position: absolute;
  z-index: 8;
  top: -1000%;
  width: 100%;
  background-color: white;
  display: block;
  transition: top 0.2s;

  &.is-open {
    top: 0;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    position: relative;
    top: 0;
  }
`;

interface NavigationProps {
  mobilenav: boolean;
  toggleMobileNav: Dispatch<SetStateAction<boolean>>;
  data: { entries: [] };
}

const Navigation: React.FC<NavigationProps> = ({ mobilenav, data }) => {
  const homeObject = {
    id: "0",
    metaDescription: "Reiseangebote",
    title: "Reiseangebote",
    uri: "",
    weiterleitung: false,
  };
  if (data.entries[0].uri !== "") {
    data.entries.unshift(homeObject);
  }
  return (
    <StyledNavigation className={mobilenav ? "is-open" : ""}>
      <NavigationList listdata={data.entries} levelIndicator={0} />
    </StyledNavigation>
  );
};
export default Navigation;
