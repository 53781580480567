import * as React from "react";
import { Link } from "gatsby";
import NavigationList from "./NavigationList";
import { breakpoints, colors } from "../../styles/variables";
import { useMatch } from "@reach/router";

import styled from "styled-components";
import "normalize.css";

const StyledNavigationListItem = styled.li`
  &.level-0 {
    margin: 10px 5px;
    transition: all 0.2s;
    padding-top: 25px;

    a {
      text-decoration: none;
      color: inherit;
      padding: 11px 20px;
      display: block;

      &:hover {
        color: ${colors.orange};
      }
      @media screen and (min-width: ${breakpoints.lg}px) {
        &:hover {
          color: ${colors.brand};
        }
      }
      &.is-active {
        &:after {
          position: absolute;
          content: "";
          top: 100%;
          left: 0;
          height: 10px;
          width: 100%;
          background-color: ${colors.brand};
        }
      }
    }

    @media screen and (min-width: ${breakpoints.lg}px) {
      position: relative;
      margin: 10px 5px;
      padding: 0;
      transition: all 0.2s;

      &:hover,
      &.is-open {
        &:before {
          background-color: white;
        }

        & > ul {
          display: block;
          background-color: ${colors.brand};
        }
      }
      &.is-active {
        &:before {
          background-color: white;
        }
      }
      a {
        white-space: nowrap;
      }

      &:last-of-type {
        ul.level-1 {
          right: 0;
          left: auto;
        }
      }
    }

    @media screen and (min-width: ${breakpoints.lg}px) {
      margin: 0;
      padding: 0;
    }
  }
  &.level-1 {
    a {
      text-decoration: none;
      color: inherit;
      padding: 3px 25px;
      display: block;
    }

    @media screen and (min-width: ${breakpoints.xl}px) {
      a {
        color: white;
        padding: 0 20px;
      }

      &.is-active,
      &:hover {
        border-left: solid 10px white;

        a {
          padding-left: 10px;
          color: white;
        }
      }
    }
  }
`;
const NavigationLink = styled(Link)``;

interface NavigationListItemProps {
  uri: string;
  title: string;
  children?: [] | undefined;
  levelIndicator: number;
  pathname: string;
  redirectto: string;
}

const NavigationListItem: React.FC<NavigationListItemProps> = ({
  uri,
  title,
  children,
  levelIndicator,
  redirectto,
}) => {
  const match = useMatch("reiseangebote/:item");
  const link = redirectto ? redirectto : uri;
  return (
    <StyledNavigationListItem className={`level-${levelIndicator}`}>
      <NavigationLink
        to={`/${link}`}
        activeClassName="is-active"
        partiallyActive={
          match && match.path === "reiseangebote/:item" ? true : false
        }
      >
        {title}
      </NavigationLink>
      {children !== undefined && children.length > 0 && (
        <NavigationList
          listdata={children}
          levelIndicator={uri.split("/").length}
        />
      )}
    </StyledNavigationListItem>
  );
};
export default NavigationListItem;
