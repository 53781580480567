import * as React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import "../static/fonts/fonts.css";
import "normalize.css";
import { useState } from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation/Navigation";
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";

import { createGlobalStyle } from "styled-components";

import FaviconApple from "../static/favicon/apple-touch-icon.png";
import Favicon32 from "../static/favicon/favicon-32x32.png";
import Favicon16 from "../static/favicon/favicon-16x16.png";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing:border-box; 
  }
`;

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
    };
  };
  cms: {
    entries: {
      id: string;
      title: string;
      uri: string;
      metaDescription: string;
      weiterleitung: boolean | false;
    };
  };
}

const DefaultLayout: React.FC = ({ children }) => {
  const [mobilenav, toggleMobileNav] = useState(false);
  return (
    <StaticQuery
      query={graphql`
        query DefaultLayoutQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
          cms {
            entries(status: "live", hasDescendants: true) {
              ... on CMS_pages_pages_Entry {
                id
                title
                uri
                metaDescription
                weiterleitung
                children {
                  id
                  uri
                  title
                }
              }
            }
          }
        }
      `}
      render={(data: StaticQueryProps) => (
        <LayoutRoot>
          <GlobalStyle />
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: "description",
                content: data.site.siteMetadata.description,
              },
              { name: "msapplication-TileColor", content: "#da532c" },
              { name: "theme-color", content: "#ffffff" },
            ]}
          >
            <link
              rel="prefetch"
              href="/fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff"
            />
            <link
              rel="prefetch"
              href="/fonts/4fe1c328-1f21-434a-8f0d-5e0cf6c70dfb.woff"
            />
            <link
              rel="prefetch"
              href="/fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff"
            />
            <link
              rel="prefetch"
              href="/fonts/7de96184-e0a5-4413-8dc0-5c15619cef19.woff"
            />
            <link rel="apple-touch-icon" sizes="180x180" href={FaviconApple} />
            <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
            <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
          </Helmet>
          <Header
            title={data.site.siteMetadata.title}
            mobilenav={mobilenav}
            toggleMobileNav={toggleMobileNav}
          />
          {mobilenav}
          <Navigation
            mobilenav={mobilenav}
            toggleMobileNav={toggleMobileNav}
            data={data.cms}
          />
          <LayoutMain>{children}</LayoutMain>
          <Footer />
        </LayoutRoot>
      )}
    />
  );
};

export default DefaultLayout;
